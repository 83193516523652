body {
  font-family: monospace;
  font-size: 1rem;
}

h1,h2,h3,h4,h5,h6,ul,li {
  padding: 0;
  margin: 0;
}

.App-header {
  text-align: center;
  padding: 50px 0;
  text-transform: uppercase;
}

.capitalize {
  text-transform: uppercase;
}

.bold {
  font-weight: bold;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}